<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{  $t('Assign badges') }}:</span>
        </v-card-title>
        <v-card-text>
          <div :key="user.id" v-for="user in assignedUsers"> {{user.firstName}} {{user.lastName}} ({{user.email}})
            <v-autocomplete
                v-model="user.badges"
                :items="courseBadges"
                filled
                chips
                :label="$t('Badges')"
                item-text="name"
                item-value="id"
                multiple
                @change="badgesChanged($event, user)"
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeBadge(data.item, user)"
                >
                  <img class="badge-image badge-image badge-image-small" v-if="data.item.image" :src="getFileUrl(data.item.image.path)"/>
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-content class="ma-0">
                  <v-list-item-title>
                    <v-row>
                      <v-col cols="6">{{data.item.name}}</v-col>
                      <v-col cols="6"><img class="badge-image" v-if="data.item.image" :src="getFileUrl(data.item.image.path)"/></v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveBadge"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from "@/components/Snackbar";
import {getFileUrl} from "@/utils/common";

export default {
  name: 'CourseBadgeAssignDialog',
  components: {  Snackbar, ConfirmDialog },
  data() {
    return {
      getFileUrl,
      badges: [],
      assignedUsers: [],
      course: undefined,
      showDialog: false,
      values: [],
      value: null,
      saveSuccess: false,
      isProductNameTaken: false,
      parentId: 0,
      quantityRequired: null,
      snackbarSuccess: {
        text: this.$t('Badges saved successfully'),
        icon: 'mdi-check-circle',
        color: 'success'
      },
    }
  },
  methods: {
    badgesChanged(event, user) {
      user.update = true
    },
    async show(course, badges) {
      this.assignedUsers = await APIService.getAssignedCourseUsers(course.id)
      this.course = course
      this.badges = badges
      this.showDialog = true
    },
    close() {
      this.assignedUsers = {}
      this.showDialog = false
    },
    async saveBadge () {
      this.assignedUsers.filter((user) => user.update === true).map(user => {
        console.log("Update user:", user.email)
        APIService.updateUser({id: user.id, badges: user.badges})
      })
      this.showDialog = false
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.close()
    },
    removeBadge(item, user) {
      user.badges = user.badges.filter(badge => badge.id !== item.id)
    },
  },
  computed: {
    ...mapState(['users']),
    courseBadges() {
      return this.course.badges.map(badgeId => this.badges.find(badge => badge.id === badgeId)).filter(badge => badge !== undefined)
    }
  },
}
</script>

<style scoped>
.badge-image {
  max-width: 100px;
  padding: 4px;
}
</style>

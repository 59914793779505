<template>
  <v-row>
    <v-col cols="12" class="text-h6">
      {{label}}
    </v-col>
    <v-col cols="6">
      <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              class="mt-0 pt-0"
              :value="date | formatLocaleDate"
              clearable
              :label="$t('date')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="clearDate"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            @change="inputChanged"
            @input="dateMenu = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="6">
      <v-menu
          ref="menu"
          v-model="timeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="timeMenu"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              class="mt-0 pt-0"
              :value="time"
              :label="$t('Time')"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
            v-if="timeMenu"
            v-model="time"
            format="24hr"
            full-width
            @click:minute="$refs.menu.save(time)"
            @change="inputChanged"
        ></v-time-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import {format, formatISO, parse, parseISO} from "date-fns";

export default {
  name: "DateTimePicker",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    }
  },
  label: {
    type: String,
  },
  data() {
    return {
      dateMenu: false,
      timeMenu: false,
      date: null,
      time: '',
    }
  },
  methods: {
    formatISOString(dateString) {
      console.log("formatISOString",dateString)
      return dateString ? formatISO(parseISO(dateString), { representation: 'date' })  : ''
    },
    clearDate() {
      this.date = null
      this.inputChanged()
    },
    inputChanged() {
      console.log("Input Changed", this.date, this.time)
      if(!this.date || !this.time) {
        console.log("Undefined emitted")
        this.$emit('input', undefined)
      } else {
        const dateTime = parse(this.date + ' ' + this.time, 'yyyy-MM-dd HH:mm', new Date()).toISOString()
        console.log("DateTimeString emitted", dateTime)
        this.$emit('input', dateTime)

      }
    },
    valueChanged() {
      this.date = this.value ? formatISO(parseISO(this.value), { representation: 'date' }) : ''
      this.time = this.value ? format(parseISO(this.value), "HH:mm") : ''
    }

  },
  watch: {
    value() {
      this.valueChanged()
    },
    time() {
        this.inputChanged()
    }
  },
  mounted() {
   this.valueChanged()
  }
}
</script>

<style scoped>

</style>

<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          class="mt-0 pt-0"
          :value="date | formatLocaleDate"
          clearable
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
          @click:clear="dateChanged(null)"
      ></v-text-field>
    </template>
    <v-date-picker
        @input="dateChanged($event)"
        :value="formatISOString(date)"
        @change="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import {formatISO, parseISO} from "date-fns";

export default {
  name: "DatePicker",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    }
  },
  label: {
    type: String,
  },
  data() {
    return {
      menu: false,
      date: undefined
    }
  },
  methods: {
    dateChanged(date) {
      this.$emit('input', date)
    },
    formatISOString(dateString) {
      return dateString ? formatISO(parseISO(dateString), { representation: 'date' })  : ''
    }
  },
  watch: {
    value(dateString) {
      this.date = this.formatISOString(dateString)
    }
  },
  mounted() {
    this.date = this.formatISOString(this.value)
  }
}
</script>

<style scoped>

</style>

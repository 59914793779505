<template>
  <div>
    <h1>{{  $t('courses') }}</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col v-if="user.role !=='user'" class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createCourse"
            >
              <v-icon left>mdi-plus</v-icon>
              {{  $t('courses') }}
            </v-btn>
            <v-btn
                rounded
                color="success"
                dark
                @click="createCSV"
                style="margin-left:10px"
            >
              <v-icon left>mdi-download</v-icon>
              CSV
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="courses"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="courses.length < 5"
          :footer-props="{
            'items-per-page-text':$t('Rows per page'),
            'items-per-page-options': [15, 50, 100, -1]
          }"
      >
        <template v-slot:item:name="{  }">
          <v-avatar
              color="primary"
              size="56"
          ></v-avatar>
        </template>
        <template v-slot:item.author="{ item }">
          <v-avatar
              v-if="item.author"
              class="mr-1"
              style="color: white; font-weight: bolder;"
              color="warning"
              size="35"
              small
          >{{item.author.firstName | uppercaseFirstLetter }}{{item.author.lastName | uppercaseFirstLetter }}</v-avatar>
          <template v-if="item.author">{{item.author.firstName}} {{item.author.lastName}}</template>
        </template>
        <template v-slot:item.status="{ item }" class="ml-0">
          <v-chip
              :color="item.status === 'draft' ?  'secondary' : 'success'"
              class="ma-0"
              small
              style="width: 80px"
          >
            {{item.status}}
          </v-chip>
        </template>
        <template v-slot:item.createdAt="{item}" class="ml-0">
          {{item.createdAt | formatLocaleDate }}
        </template>
        <template v-slot:item.updatedAt="{item}" class="ml-0">
          {{item.updatedAt | formatLocaleDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              v-if="user.role !== 'user'"
              small
              class="mr-2"
              @click="copyItem(item)"
          >
            mdi-content-copy
          </v-icon>
          <v-icon
              v-if="user.role !== 'user'"
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <course-edit-dialog ref="courseEditDialog" @updated="fetchCourses"/>
    <confirm-dialog ref="confirmDelete"/>
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
  </div>
</template>

<script>
import CourseEditDialog from '@/components/CourseEditDialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import {mapState} from "vuex";
import Papa from "papaparse";
import {escapeForCSV} from "@/utils/common";

export default {
  name: 'CourseList',
  mixins: [vuetifyTableMixin],
  components: { CourseEditDialog, ConfirmDialog },
  data () {
    return {
      courses: [],
      search: '',
      showLoader: false
    }
  },
  methods: {
    createCourse () {
      this.$refs.courseEditDialog.create()
    },
    copyItem(course) {
      APIService.getCourse(course).then(course => {
        course.title = `${course.title} Kopie`
        delete course.id
        delete course.createdAt
        delete course.updatedAt
        delete course.author
        delete course.lastUpdatedBy
        course.status = 'draft'
        course.chapters = course.chapters.map(chapter => ({...chapter, startDate: undefined}))
        this.$refs.courseEditDialog.edit(course)
      })
    },
    editItem (course) {
      APIService.getCourse(course).then(course => {
        console.log('Course', course)
        this.$refs.courseEditDialog.edit(course)
      })
    },
    async deleteItem (course) {
      await this.$refs.confirmDelete.show({
        title: this.$t('Remove Course'),
        text: this.$t('Do you want to remove this course?')+" "+course.title,
        confirm: this.$t('Remove')
      })
      await APIService.deleteCourse(course)
      this.courses = await APIService.getCourses()
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    async fetchCourses() {
      this.showLoader = true;
      this.courses = await APIService.getCourses();
      this.showLoader = false;
    },
    async createCSV(){
      let csvData = [];
      csvData.push([]);
      /*
      for(let i=0; i < this.headers.length; i++){
        csvData[0].push(this.headers[i].text);
      }*/

      let headers = [
        "Lfd. Nr. ",
          "Hauptkategorie - Tags",
          "Unterkategorie 1 (siehe Tags)",
          "Unterkategorie 2 (siehe Tags)",
          "Sprache (siehe Tags)",
          "Bezeichnung",
          "Auflage ",
          "Beschreibung (lang)",
          "Erstellt von",
          "Autor",
          "Artikelnummer",
          "Produktart",
          "Lehrjahr",
          "Lernfeld ",
          "Berufsbildposition",
          "Berufsbildposition",
          "Seitenzahl",
          "Bearbeitungsdauer",
          "Beschreibung (kurz)",
          "Suchbegriffe",
          "Vorgängerprodukt",
          "Nachfolgeprodukt",
          "Hardware"
      ];
      for(let i=0; i < headers.length; i++){
        csvData[0].push(headers[i]);
      }

      let alltags = await APIService.getTags();

      for(let i=0; i < this.courses.length; i++){
        let current = this.courses[i];
        let tagtext = "";
        for(let j=0; j < this.courses[i].tags.length; j++){
          for(let k=0; k< alltags.length; k++){
            if(alltags[k].id == this.courses[i].tags[j]){
              tagtext += alltags[k].name+", ";
            }
          }
        }

        let desc = current.description ? current.description : "";
        desc = escapeForCSV(desc);

        let authorString = "";
        authorString += (current.author && current.author.firstName) ? current.author.firstName : "";
        authorString += (authorString.length > 0) ? " " : "";
        authorString += (current.author && current.author.lastName) ? current.author.lastName : "";
        authorString += (authorString.length > 0) ? " " : "";
        authorString += (current.author && current.author.email) ? (" - " + current.author.email) : "";

        let row = [(i+2), tagtext, "", "", "", current.title ? current.title : "", current.edition ? current.edition : "", desc, authorString];
        csvData.push(row);
      }
      let csv = Papa.unparse(csvData);

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'kurse.csv';
      anchor.click();
    }
  },
  computed: {
    ...mapState(['user']),
    headers(){ return [
      {
        text: this.$t('title'),
        align: 'start',
        sortable: true,
        value: 'title',
        width: 400
      },
      {
        text: this.$t('Author'),
        value: 'author',
        sortable: true
      },
      {
        text: this.$t('Created on'),
        value: 'createdAt',
        sortable: true
      },
      {
        text: this.$t('Last modified on'),
        value: 'updatedAt',
        sortable: true
      },
      {
        text: this.$t('State'),
        value: 'status',
        sortable: true
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        align: 'end',
        width: 100,
      }
    ]},
  },
  async beforeRouteEnter(to, from, next) {
    const courses = await APIService.getCourses()
    next(vm => vm.courses = courses)
  },
  async beforeRouteUpdate(to,from,next) {
    this.courses = await APIService.getCourses()
    next()
  }
}
</script>

<style scoped>

</style>
